@charset "UTF-8";


$red : #d3514c;
$beige: #ebd8b8;
/*
normalise
*/
@import "vendors/animate";
@import "componement/base";
@import "componement/mixins";

@import "componement/over_materialize";
@import "componement/icons.scss";
@import "componement/menu";
@import "vendors/grid";
@import "vendors/slick-theme";
@import "vendors/slick";


@import url('https://fonts.googleapis.com/css?family=PT+Serif:400,400i,700|Roboto:400,700');
/*
font-family: 'Roboto', sans-serif;
font-family: 'PT Serif', serif;
*/
/*
###########################
GO
########################### 
*/

.luminosity:before{    
	mix-blend-mode: hard-light;
}
      
body,html{
	    min-height: 100vh;
		color: #41484A;
		font-family: 'SuisseIntl-Regular';
		font-weight: 400 !important;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-font-smoothing: antialiased;
		-ms-font-smoothing: antialiased;
		-o-font-smoothing: antialiased;
		letter-spacing: 0px;
}
body main{

	@include transition(all .3s ease);
	
}

b,strong,small {
	font-family: 'SuisseIntl-Bold';
}


figure{
	outline: 0;
	line-height: 0;
	margin: 0;
	line-height: 0;
}	
section {

	width: 100%;
	overflow: hidden;
}
.hero {
	height: 100vh;
}
.hero .valign-wrapper,
.hero .container {
	height: 100%;
}
.hero2 {
	height: 450px;

}
.bgblu:before {
	background-color: rgba(#41484A,.6);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	display: block;
}
.hero small,.hero h2 {
	color: #FFF;
}
.hero h2 {
		font-size: 3rem;
}
h1,h2,h3,h4,h5,h6,#tooltip-bottom {
	font-family: 'PT Serif', serif;
	    /*font-weight: 400 !important;*/
	    text-rendering: auto;
	    -webkit-font-smoothing: antialiased;
	    -moz-font-smoothing: antialiased;
	    -ms-font-smoothing: antialiased;
	    -o-font-smoothing: antialiased;
	    letter-spacing: 0px;
		    margin-top: 5px;
			
		
}
.btn {
	background-color: #C4A237;
}

#tooltip-bottom {
   background: #C4A237;
   position: absolute;

   border: 1px solid transparent;
   padding-bottom: 1px;
   height: auto;
   border-radius: 0!important;
   box-shadow: none;

   color: #FFF;
   padding-top: 1px;
   font-size: 1.2rem;
   line-height: 3rem;
   text-transform: none;
   padding-left: 2rem;
   padding-right: 2rem;
   position: absolute;
       bottom: 0;
       right: 0;
       left: auto;
}
#tooltip-bottom:before {
   content:"";
   position: absolute;
   width: 0;
   height: 0;
   border-right: 20px solid transparent;
   border-bottom: 20px solid #C4A237;
   border-left: 0px solid transparent;
   margin: 0;
   margin-top: -20px;
}
h1,h2,h3,h4,h5,h6  {
	color:  #C4A237;
}

.or {
	color:  #C4A237;
}

.padd1 {
	padding: 10%;
}
#agence {
	    padding-top: 80px;
}
h3 {
	font-size: 2rem;
	    line-height: 2.6rem;
	
}
.list1 li a {
	color: #41484A;
	line-height: 2.5rem;
	font-size: 1.4rem;
}
.bgor {
	background-color: #C4A237;
	color: #FFF;
}
.cntr {
	text-align: center;
}
.bgor h1,
.bgor h2,
.bgor h3,
.bgor h4,
.bgor h5,
.bgor h6,
.bgor a {
	color: #FFF;
}
.bgor a {
	    font-size: 75%;
}
#newshome .newshead2 a {
	margin-top: 95px;
	position: relative;
	display: block;
	text-align: right;
	color: #8E9798;
}
.newsitem {
	width: 34vw;
	float: left;
	display: block;
}
.newsitem .caption {
	background-color: #8E9798;
	height: 206px;
	display: block;
}
.slick-initialized .slick-slide,
.newsitem {
    display: block;
    position: relative;
    width: 36vw;
	padding: 20px;
    overflow: hidden;
}

.slick-next,
.slick-prev,
.slick-next:focus,
.slick-prev:focus,
.slick-next:hover,
.slick-prev:hover,
 {
	background-color: #C4A237;
	width: 50px;
	    height: 50px;
	    border-radius: 50px;
		z-index: 10;
}
.slick-next:before,.slick-prev:before {
	font-family: 'Linearicons';
	    speak: none;
	    font-style: normal;
	    font-weight: 400;
	    font-variant: normal;
	    text-transform: none;
	    line-height: 1;
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;
		
}
.slick-next,
.slick-prev,
.slick-next:focus,
.slick-prev:focus,
.slick-next:hover,
.slick-prev:hover {
		display: block;
		opacity: 1;
}

.slick-next {
	right: 7.5vw;
}
.slick-prev {
	left: 7.5vw;
}
.slick-next:before {
           content: "\e944";
    
}

.slick-prev:before {
    
	content: "\e943";
}

.newsitem h3 {
	color: #41484A;
}
.newsitem small {
	margin-top: 40px;
	display: inline-block;
	color: #8E9798;
}
.newsitem a {
	color: #C4A237;
}
.newshome,
#services {
	padding-top: 80px;
	padding-bottom: 80px;
}


.parts {
	max-width: 450px;
}
.slider {
    position: relative;
        height: 480px;
    width: 100%;
}
.newshome {
	padding-bottom: 0;
}
.infc {
	padding-bottom: 80px;
}
.bgor a {
    font-size: 1rem;
}
.ccr .valign-wrapper {
	height: 50px;
}
.btmright {
	color: #41484A;
	padding-top: 20px;
}


footer {
	background-color: #41484A;
	padding-bottom: 80px;
	   padding-top: 80px;
	   color: #FFF;
	
}
footer .btn-floating {
	background-color: #8E9798;
	box-shadow: none;
	margin-right: 5px;
	margin-left: 5px;
}
footer .btn-floating:hover {
	background-color: #C4A237;
	box-shadow: none;
	margin-right: 5px;
	margin-left: 5px;
	opacity: 1;
}
footer i:before {
	    line-height: inherit!important;
}
footer .footerend {
	color: #8E9798;
	font-size: 75%;
}
.btfullwidth {
	margin: 1.46rem 0 1.168rem;
	    color: #FFF;
	    display: block;
	    position: relative;
		line-height: 2.5rem;
		font-size: 1.4rem;
	
}
.btfullwidth i {
	text-align: right;
	display: block;
	float: right;
}
.toright {
	text-align: right;
}
.footerleft {
	    line-height: 2.6rem;
}
.infc .container {
	padding-top: 80px;
}
nav ul a:hover,
nav ul a.active {
	background-color: #41484A;
	color: #C4A237;

}

nav ul a.active {
	background-color: #41484A;
	color: #C4A237;
	font-family: 'SuisseIntl-Bold';
}
.navtop li a {
	line-height: 44px;
}
.navtop ul {
	    margin-top: 10px;
}
.bloc {
	position: relative;
	    display: block;
	    width: 100%;
}
.ccr .bloc a {
	float: right;
	display: block;
	position: relative;
}
.container {
    margin: 0 auto;
    width: 80%;
}
html {
    font-size: 1rem;
    line-height: 1.6rem;
}
.padd2 {
	padding-bottom: 80px;
	padding-top: 80px;
}
.container2 {
	width: 75%;
}
.container3 {
	width: 85%;
}
.container4 {
	width: 65%;
}
.servicesbloc li a {

	color: #FFF;
	
	
}
.servicesbloc .valign-wrapper {
	height: 400px;
	padding: 0;
	border: 10px solid #FFF;
}
.servicesbloc li h3,
.servicesbloc li a {
	color: #FFF;
}


.servicesbloc > .valign-wrapper > div,
.servicesbloc a.valign-wrapper > div {
	display: block;
	    position: relative;
	    width: 100%;
		padding: 10%;
}
.servicesbloc a.valign-wrapper {
	background-color: #C4A237;
	color: #FFF;
}

.input-field {
    position: relative;
    margin-top: 0;
	margin-bottom: 0px;

}
.paddtop {
	margin-top: 80px;
}
.fullwidth {
	width: 100%;
}
.gridchiffres li {
	float: left;
	position: relative;
	display: block;
}
.gridchiffres small,
.resultchiffre small {
	font-family: 'SuisseIntl-Regular';
}
.gridchiffres p {
	padding: 0;
	    margin: 0;
}
.resultchiffre p,
.resultchiffre h3,
.resultchiffre h2 {
	 margin: 0;
}
h2 {
    font-size: 3rem;
}
form p.radio {
    margin-bottom: 0;
    margin: 0;
}

.bigradio {
	
}
.bigradio [type=radio]:checked+label,
.bigradio [type=radio]:not(:checked)+label,
.bigradio [type=radio]:checked+label:before, 
.bigradio [type=radio]:not(:checked)+label:before {
    
    height: 14vw;
}
.bigradio [type=radio]:checked+label,
.bigradio [type=radio]:not(:checked)+label, {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	    display: inline-grid;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 10px;
	line-height: 1rem;
	text-align: center;
}

.select-dropdown li.disabled, .select-dropdown li.disabled>span, .select-dropdown li.optgroup {
   display: none;
}

.centr {
	text-align: center;
}
.response small {
	font-family: 'SuisseIntl-Regular';
}
.response {
	display: block;
	padding-top: 20px;
}
button[type=submit]{
    border: 1px solid transparent;
    padding-bottom: 1px;
    height: auto;
    border-radius: 0!important;
    box-shadow: none;
	background-color: #C4A237;
    color: #FFF;
}
.error {
	color: #CC2B2B;
	font-family: 'PT Serif',serif;
	-webkit-font-smoothing: antialiased;
	border-color: #CC2B2B!important;
}
input:not([type]), input[type=date], input[type=datetime-local], input[type=datetime], input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], textarea.materialize-textarea,
.select-wrapper input.select-dropdown {
    margin: 0 0 10px;
}

.select-wrapper span.caret {
    font-size: 10px;
    line-height: 10px;
    display: block;
    line-height: 20px!important;
    padding-top: 0;
        margin-top: 21px;
		    margin-right: 17px;
}

input:not([type]).valid, input:not([type]):focus.valid, input[type=date].valid, input[type=date]:focus.valid, input[type=datetime-local].valid, input[type=datetime-local]:focus.valid, input[type=datetime].valid, input[type=datetime]:focus.valid, input[type=email].valid, input[type=email]:focus.valid, input[type=number].valid, input[type=number]:focus.valid, input[type=password].valid, input[type=password]:focus.valid, input[type=search].valid, input[type=search]:focus.valid, input[type=tel].valid, input[type=tel]:focus.valid, input[type=text].valid, input[type=text]:focus.valid, input[type=time].valid, input[type=time]:focus.valid, input[type=url].valid, input[type=url]:focus.valid, textarea.materialize-textarea.valid, textarea.materialize-textarea:focus.valid {
    border-bottom: 1px solid #C4A237;
    box-shadow: 0 1px 0 0 #C4A237;
}
.imgtop {
	height: 400px;
	background-color: #8E9798;
}
.articleitem h3,
.articleitem a {
	color: #41484A;
}

.articleimg {
	height: 400px;
	width: 100%;
}
/*.select-wrapper .error {
	position: absolute;
}*/
.actulist .grid {
	margin-left: -3%;
}
.actulist [class*=col-] {
    padding-left: 3%;
    margin-bottom: 3%;
}
.articleimg2 {
	height: 300px;
	width: 100%;
	background-color: #8E9798;
}

textarea {

    border: 1px solid #C4A237;
    padding: 20px;
    box-sizing: border-box;
	height: 300px;
	font-family: 'PT Serif',serif;
		-webkit-font-smoothing: antialiased;
}
.listicons li {
	    line-height: 35px;
}
.listicons li i {
	font-size: 23px;
	    margin-right: 18px;
}
.hero {
	    min-height: 450px;
}
.gridtaux h2 {
	margin-bottom: 0;
}
.gridtaux p {
	margin-top: 0;
}

.slidercontainer,
.slider,
.slick-list.draggable {
	overflow: visible;
}
.newsitem.slick-slide {
	opacity: .6;
}
.newsitem.slick-slide.slick-active {
	opacity: 1;
}
.ccr .col-1-2, 
.ccr h3 {
	margin-bottom: 0;
	margin-top: 0;
}
footer .btfullwidth i {

    line-height: 40px;
}
.servicesbloc a.valign-wrapper {
	margin-bottom: 0;
}

titrelinks h3 {
    font-size: 2rem;
    line-height: 2.6rem;
    margin-top: 0;
}
.list1 {
	    margin-top: 8px;
}
.list1 li a {

	line-height: 2.6rem;
}
footer .btfullwidth {
	margin-top: 9px;
}
.newsitem small {
    margin-top: 65px;
    display: inline-block;
    color: #8E9798;
}

@import "componement/responsive";



@charset "UTF-8";
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}
@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
     -moz-box-sizing: $box-model; // Firefox <= 19
          box-sizing: $box-model;
}

@mixin rotate($deg) {
 -webkit-transform: rotate($deg);
 -moz-transform: rotate($deg);
 -o-transform: rotate($deg);
 -ms-transform: rotate($deg);
 transform: rotate($deg);
}

@mixin origin($args){
	transform-origin:$args;
	-webkit-transform-origin: $args;
	-moz-transform-origin: $args;
	-ms-transform-origin: $args;
	-o-transform-origin: $args;
}

/*@mixin transition($seconds) {
-webkit-transition: $seconds ease-in-out;
-moz-transition: $seconds ease-in-out;
-o-transition: $seconds ease-in-out;
transition: $seconds ease-in-out;
}*/

@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin mobile {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}


@keyframes animate2 {
  0% {
    box-shadow: inset #333 0 0 0 17px;
    transform: rotate(-140deg);
  }
  50% {
    box-shadow: inset #333 0 0 0 2px;
  }
  100% {
    box-shadow: inset #333 0 0 0 17px;
    transform: rotate(140deg);
  }
}


.typed-cursor{
    opacity: 1;
    -webkit-animation: blink 0.7s infinite;
    -moz-animation: blink 0.7s infinite;
    animation: blink 0.7s infinite;
}
@keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
@-moz-keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
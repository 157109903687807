@charset "UTF-8";


$pad: 0px;

* {
  @include box-sizing(border-box);
}



.grid {
  margin: 0 0 $pad 0;
  position: relative;
  &:after {
    /* Or @extend clearfix */
    content: "";
    display: table;
    clear: both;
  }
}

[class*='col-'] {
	float: left;
	position: relative;
	
  padding-right: $pad;
  .grid &:last-of-type {
  	padding-right: 0;
  }
}
.col-2-3 {
	width: 66.66%;
}
.col-1-3 {
	width: 33.33%;
}
.col-1-2 {
	width: 50%;
}
.col-1-4 {
	width: 25%;
}
.col-1-8 {
	width: 12.5%;
}

.col-1-5 {
	width: 20%;
}
.col-2-5 {
	width: 40%;
}
.col-4-5 {
	width: 80%;
}


/* Opt-in outside padding */
.grid-pad {
  padding: $pad 0 $pad $pad;
  [class*='col-']:last-of-type {
    padding-right: $pad;
  }
}




.grid {
    margin: 0;
    position: relative;
    margin-left: -10px;
}
[class*=col-] {
    float: left;
    position: relative;
    padding-right: 0;
    padding-left: 10px;
	margin-bottom: 10px;
}

.col-1-1 {
	width: 100%;

}

@media (max-width: 1024px){
	.col-2-3,
	.col-1-3,
	.col-1-2,
	.col-1-4,
	.col-1-8,
	.col-1-5,
	.col-2-5,
	.col-4-5 {
		width: 50%;
	}
}


@media (max-width: 768px){


	.col-2-3,
	.col-1-3,
	.col-1-2,
	.col-1-4,
	.col-1-8,
	.col-1-5,
	.col-2-5 {
		width: 50%;
		
	}

}